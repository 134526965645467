import React, {useState, useRef, useEffect} from 'react'
import moment from 'moment/moment'
import RichTooltip from '../RichTooltip'
import {Colors} from '../../assets/styles'
import useDoOnceTimer from '../../../vendorManagement/src/hooks/useDoOnceTimer'
import {v4 as uuid} from 'uuid'
import Calendar from '../Calendar/Calendar'
import PropTypes from 'prop-types'

export const DUMMY_EVENT_ID = uuid()

function CalendarWithToolTip(props) {
  const {setTimer} = useDoOnceTimer()

  const recentlyClosed = useRef(false)

  const [focusedEventId, setFocusedEventId] = useState(null)
  const [crudReservationDetails, setCrudReservationDetails] = useState(null)
  const [dummyEvent, setDummyEvent] = useState(null)
  const [anchorRef, setAnchorRef] = useState(null)

  /**
   * @param {CalendarEventConfig} updatedEvent
   */
  const handleUpdateDummyEvent = updatedEvent => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(updatedEvent && updatedEvent.id === DUMMY_EVENT_ID && updatedEvent.start && updatedEvent.end && updatedEvent.column)) {
      return
    }

    setDummyEvent({...dummyEvent, ...updatedEvent})
  }

  /**
   * @param {CalendarEventConfig} ev
   */
  const handleChangeEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current) {
      return
    }

    setCrudReservationDetails({id: ev.id, ...props.eventToObject(ev)})
    setFocusedEventId(ev.id)
  }

  /**
   * @param {CalendarEventConfig} ev
   */
  const handleClickEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current) {
      return
    }

    setFocusedEventId(ev.id)
  }

  /**
   * @param {CalendarEventConfig} ev
   */
  const handleCreateEvent = ev => {
    // assume they were just trying to click off the panel
    if (recentlyClosed.current || props.disableEventCreation) {
      return
    }

    setCrudReservationDetails({id: ev.id || DUMMY_EVENT_ID, ...props.eventToObject(ev)})
    setDummyEvent({...ev, isDisabled: true, color: Colors.rxrGreenColor, content: 'New Appointment', id: DUMMY_EVENT_ID})
    setFocusedEventId(DUMMY_EVENT_ID)
  }

  const handleCloseMenu = () => {
    // this is used to let them click-off onto an empty square without re-opening another create dialog
    recentlyClosed.current = true
    setTimer(
      'recently-closed-timer',
      () => {
        recentlyClosed.current = false
        // if we unset the dummy event too soon the tooltip anchors briefly to the frame and it looks bad
        // don't believe me? move this outside the timer and watch.
        setDummyEvent(null)
      },
      600,
    )

    setCrudReservationDetails(null)
    setAnchorRef(null)
    setFocusedEventId(null)
    props.renderEventMenu(crudReservationDetails, null, handleUpdateDummyEvent)
  }

  const isTipOpen = !!(focusedEventId && anchorRef)

  const finalEventsArray = dummyEvent ? [...props.events, dummyEvent] : props.events

  return (
    <React.Fragment>
      <Calendar
        columns={props.columns}
        events={finalEventsArray}
        onChangeEvent={handleChangeEvent}
        onClickEvent={handleClickEvent}
        onCreateEvent={handleCreateEvent}
        startTime={props.calendarStartTime}
        endTime={props.calendarEndTime}
        focusedEventId={focusedEventId}
        disableEventDragging={props.disableEventDragging}
        onRenderFocusedEvent={a => setAnchorRef(a)}
      />

      <RichTooltip open={isTipOpen} anchorEl={anchorRef} onClose={handleCloseMenu} placement={'right'}>
        {isTipOpen && props.renderEventMenu(crudReservationDetails, focusedEventId, handleUpdateDummyEvent, handleCloseMenu)}
      </RichTooltip>
    </React.Fragment>
  )
}

CalendarWithToolTip.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string).isRequired, // array of
  events: PropTypes.array.isRequired, // array of @type CalendarEventConfig
  renderEventMenu: PropTypes.func.isRequired,
  calendarEndTime: PropTypes.number.isRequired,
  calendarStartTime: PropTypes.number.isRequired,
  eventToObject: PropTypes.func.isRequired,
  disableEventCreation: PropTypes.bool,
  disableEventDragging: PropTypes.bool,
  highlightedColumn: PropTypes.string,
}

export default CalendarWithToolTip
