import React, {useEffect, useRef, useState} from 'react'
import {v4 as uuid} from 'uuid'
import {useSelector} from 'react-redux'
import CalendarWithTooltip, {DUMMY_EVENT_ID} from './CalendarWithTooltip'
import SimpleSpinner from '../SimpleSpinner'
import moment from 'moment/moment'
import {LOADING_STATE_ERROR, LOADING_STATE_LOADING} from '../../constants/AppConstants'
import EmptyStateIconAndMessage from '../EmptyStateIconAndMessage'
import RXRIcon from '../RXRIcon'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import useBuildingColors from '../../hooks/useBuildingColors'
import VendorAppointmentForm from '../Bookings/VendorAppointmentForm'
import {Spacing, Colors} from '../../assets/styles'
import {Grid} from '@mui/material'
import {loadVendorServiceResourceSettings} from '../../lib/queries'
import {updateVendorServiceResourceSettings} from '../../lib/queries'
import TimeBlockOffForm from '../Bookings/TimeBlockOffForm'
import {rxrMediumLightGreyColor} from '../../../../src/assets/styles/color'

function WeekCalendar(props) {
  const classes = useStyles()
  const loadingStatus = useSelector(state => state.Appointments.loading.status)
  const [isDataFetchingOrUpadting, setIsDataFetchingOrUpdating] = useState(true)
  const servicesLookup = useSelector(state => state.User.servicesLookup)
  const getBuildingColor = useBuildingColors()
  const editingEvent = useRef()
  const editingClosure = useRef()
  const serviceIdsByBuilding = useSelector(state => state.User.serviceIdsByBuilding)
  /** @type {Array<CalendarEventConfig>} events */
  const [events, setEvents] = useState([])

  /** @type {Array<CalendarEventConfig>} events */
  const [closureEvents, setClosureEvents] = useState([])
  const [closuresLookup, setClosuresLookup] = useState({})

  /** @type {closedEvents: Array<CalendarEventConfig>, start: numner, end: numbner} */
  const [generalCalendarAvailability, setGeneralCalendarAvailability] = useState({})
  const buildingResourceSettingsCache = useRef({})

  const startOfWeek = moment(props.date).startOf('week')
  const endOfWeek = moment(props.date).endOf('week')
  // columns are always the days of the week starting with Sunday ("Sun")
  const columns = [...new Array(7)].map((e, i) => moment(startOfWeek).add(i, 'days').format('ddd'))

  useEffect(() => {
    copyAppointmentsToEvents()
  }, [props.appointments])

  useEffect(() => {
    loadVendorServiceResourceSettingsForSelectedBuildings().then()
  }, [props.selectedBuildings])

  useEffect(() => {
    setClosureEvents(createClosureEvents(Object.values(closuresLookup)))
  }, [closuresLookup, props.selectedBuildings])

  function copyAppointmentsToEvents() {
    setEvents(
      props.appointments.map(a => {
        const service = servicesLookup[a.vendorServiceId]
        return {
          id: a.id,
          color: getBuildingColor(a.buildingId),
          ...getColumnStartAndEndTimes(a),
          content: (
            <div className={classes.eventWrapper}>
              <strong>{service.label}</strong>
              <p>{a.resident.unitNumber}</p>
              <p>{a.resident.displayName}</p>
            </div>
          ),
        }
      }),
    )
  }

  /**
   * @param {CalendarEventConfig} ev
   */
  function eventToAppointment(ev) {
    const startAt = moment(startOfWeek).add(columns.indexOf(ev.column), 'days').startOf('day').add(ev.start, 'minutes').toDate()
    const endAt = moment(startAt)
      .add(ev.end - ev.start, 'minutes')
      .toDate()

    return {startAt: startAt, endAt: endAt}
  }

  /**
   * @param {*} newData
   */
  const handleUpdateAppointment = newData => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    // update our reservation event accordingly
    const e = events.find(e => e.id === newData.id)
    if (e) {
      editingEvent.current = newData.id
      Object.assign(e, getColumnStartAndEndTimes(newData))
      setEvents([...events])
    }
  }

  const handleUpdateClosure = newData => {
    // only change our box if the new form has a start and end time, otherwise we just keep previous settings
    if (!(newData && newData.startAt && newData.endAt)) {
      return
    }

    // update our closure event accordingly
    const e = closureEvents.find(e => e.id === newData.id)
    if (e) {
      editingClosure.current = newData.id
      Object.assign(e, getColumnStartAndEndTimes(newData))
      setClosureEvents([...closureEvents])
    }
  }

  /**
   * @param {Array<ResourceClosure>} closures
   * @returns {*}
   */
  const createClosureEvents = closures => {
    const splitClosureIntoDays = closure => {
      const events = []
      const start = new Date(closure.startAt)
      const end = new Date(closure.endAt)

      for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
        const eventStart = new Date(date)
        const eventEnd = new Date(date)

        if (date.toDateString() === start.toDateString()) {
          eventStart.setHours(start.getHours(), start.getMinutes(), start.getSeconds())
        } else {
          eventStart.setHours(0, 0, 0)
        }

        if (date.toDateString() === end.toDateString()) {
          eventEnd.setHours(end.getHours(), end.getMinutes(), end.getSeconds())
        } else {
          eventEnd.setHours(23, 59, 59)
        }

        const selectedAssociatedBuildingIds = closure.buildingIds.filter(b => props.selectedBuildings.includes(b))

        events.push({
          id: closure.id, // TODO: closures that span multiple days would have the same id and will probably break things
          color: fadeColor(
            selectedAssociatedBuildingIds.length === 1 ? getBuildingColor(selectedAssociatedBuildingIds[0]) : '#000000',
            0.5,
          ),
          startAt: eventStart.toISOString(),
          endAt: eventEnd.toISOString(),
          ...getColumnStartAndEndTimes({startAt: eventStart.toISOString(), endAt: eventEnd.toISOString()}),
          content: (
            <div className={classes.eventWrapper}>
              <strong>{closure.label}</strong>
            </div>
          ),
        })
      }
      return events
    }
    return closures.flatMap(c => splitClosureIntoDays(c))
  }

  const createOrUpdateCalendarTimeBlockOff = async (form, isDeleted) => {
    let singleChangeNeededAndMade = false
    setIsDataFetchingOrUpdating(true)
    for (const buildingId of form.buildingIds) {
      let willMakeSingleChange = false
      // start with our last loaded state
      const updateInput = {...buildingResourceSettingsCache.current[buildingId]}

      const thisClosure = closuresLookup[form.id]

      if (thisClosure) {
        const otherClosures = updateInput.closures.filter(c => c.id !== thisClosure.id)

        if (isDeleted) {
          // we must remove it, keeping only the other closures
          updateInput.closures = otherClosures
        } else {
          // we update it
          updateInput.closures = [
            ...otherClosures,
            {...thisClosure, label: form.notes, startAt: form.startAt.toISOString(), endAt: form.endAt.toISOString()},
          ]
        }

        // if this closure already existed, then we really only need to update it once since it's shared by the other buildings in form.buildingIds
        // So we set this flag indicating we only need to call update once
        willMakeSingleChange = true
      } else {
        // for new closures, we must create it at every building
        updateInput.closures = [
          ...updateInput.closures,
          {label: form.notes, startAt: form.startAt.toISOString(), endAt: form.endAt.toISOString()},
        ]
      }

      // if we only need to update once, we do it here. Every subsequent time we use the updateInput as if it were the response
      const response = singleChangeNeededAndMade ? updateInput : await updateVendorServiceResourceSettings(updateInput)
      handleUpdatedResourceSettingsForBuilding(response, buildingId, willMakeSingleChange && !singleChangeNeededAndMade)

      if (willMakeSingleChange) {
        singleChangeNeededAndMade = true
      }
    }
    editingClosure.current = null
    setIsDataFetchingOrUpdating(false)
  }

  async function loadVendorServiceResourceSettingsForSelectedBuildings() {
    setIsDataFetchingOrUpdating(true)

    // first, we load the settings for each selected building (if not already loaded)
    await Promise.all(
      props.selectedBuildings.map(async buildingId => {
        if (!buildingResourceSettingsCache.current[buildingId]) {
          const thisBuildingSettings = await loadVendorServiceResourceSettings(serviceIdsByBuilding[buildingId][0])
          // this function updates the buildingResourceSettingsCache.current cache
          handleUpdatedResourceSettingsForBuilding(thisBuildingSettings, buildingId)
        }
      }),
    )

    // store a map from buildingid <> general availability array for convenience
    const buildingToGAMap = props.selectedBuildings.reduce((acc, b) => {
      acc[b] = buildingResourceSettingsCache.current[b].generalAvailability
      return acc
    }, {})

    // next, we determine each calendar day's start/end time based on the general availability of all selected buildings
    /** @type {Array<{start: number, end: number}>} */
    const byDayStartAndEnd = [...new Array(7)].map((_, dow) => ({
      start: Math.min(1440, ...Object.values(buildingToGAMap).flatMap(ga => ga.filter(g => g.dayOfWeek === dow).map(g => g.startTime))),
      end: Math.max(0, ...Object.values(buildingToGAMap).flatMap(ga => ga.filter(g => g.dayOfWeek === dow).map(g => g.endTime))),
    }))

    // the calendar bounds are the earliest start time and latest end time of all selected buildings across all days
    const calendarBounds = {
      start: Math.min(1440, ...byDayStartAndEnd.map(dayBounds => dayBounds.start)),
      end: Math.max(0, ...byDayStartAndEnd.map(dayBounds => dayBounds.end)),
    }

    // the closed events are the times that all buildings are closed on a given day within the period defined by calendarBounds
    const closedEvents = byDayStartAndEnd.reduce((acc, dayBounds, dow) => {
      const closedEventDefaults = {
        color: Colors.rxrMediumLightGreyColor,
        column: columns[dow],
        content: 'Closed',
        isDisabled: true,
      }

      if (dayBounds.start > dayBounds.end) {
        // this happens when there are no general availability settings for this day -- therefore the closure should be the entire day
        acc.push({
          ...closedEventDefaults,
          id: `closed-complete-${uuid()}`,
          start: calendarBounds.start,
          end: calendarBounds.end,
        })
      } else {
        if (dayBounds.start > calendarBounds.start) {
          acc.push({
            ...closedEventDefaults,
            id: `closed-start-${uuid()}`,
            start: dayBounds.start,
            end: calendarBounds.start,
          })
        }
        if (dayBounds.end < calendarBounds.end) {
          acc.push({
            ...closedEventDefaults,
            id: `closed-end-${uuid()}`,
            start: dayBounds.end,
            end: calendarBounds.end,
          })
        }
      }

      return acc
    }, [])

    setGeneralCalendarAvailability({
      start: calendarBounds.start,
      end: calendarBounds.end,
      closedEvents: closedEvents,
    })

    setIsDataFetchingOrUpdating(false)
  }

  /**
   * @param {VendorServiceResourceSettings} settings
   * @param {string} buildingId
   * @param {boolean?} reset
   */
  function handleUpdatedResourceSettingsForBuilding(settings, buildingId, reset = false) {
    // save to cache
    buildingResourceSettingsCache.current[buildingId] = settings

    const closuresLookupForCurrentBuilding = settings.closures.reduce((acc, c) => {
      // we need to add the buildingId here because the TimeBlockOff form is expecting it
      acc[c.id] = {...c, buildingIds: [buildingId]}
      return acc
    }, {})

    setClosuresLookup(before => {
      if (reset) {
        return {...closuresLookupForCurrentBuilding}
      }
      Object.keys(closuresLookupForCurrentBuilding).forEach(closureId => {
        if (before[closureId]) {
          before[closureId].buildingIds = [...new Set([...before[closureId].buildingIds, buildingId])]
          delete closuresLookupForCurrentBuilding[closureId]
        }
      })

      return {...before, ...closuresLookupForCurrentBuilding}
    })
  }

  const isCurrentWeek = moment() >= startOfWeek && moment() <= endOfWeek

  const filteredClosureEvents = closureEvents.filter(
    cEv =>
      (moment(cEv.startAt).isBetween(startOfWeek, endOfWeek) || moment(cEv.endAt).isBetween(startOfWeek, endOfWeek)) &&
      props.selectedBuildings.some(b => closuresLookup[cEv.id] && closuresLookup[cEv.id].buildingIds.includes(b)),
  )

  return (
    <div className={classes.container}>
      {loadingStatus === LOADING_STATE_LOADING || isDataFetchingOrUpadting ? (
        <div className={classes.loading}>
          <SimpleSpinner size={SimpleSpinner.SIZE_LARGE} />
        </div>
      ) : loadingStatus === LOADING_STATE_ERROR ? (
        <div className={classes.loading}>
          <EmptyStateIconAndMessage message={'Error loading appointments'} icon={RXRIcon.CLOSE} />
        </div>
      ) : (
        <CalendarWithTooltip
          eventToObject={eventToAppointment}
          columns={columns}
          events={events.concat(generalCalendarAvailability.closedEvents).concat(filteredClosureEvents)}
          highlightedColumn={isCurrentWeek ? moment().format('ddd') : null}
          calendarStartTime={generalCalendarAvailability.start}
          calendarEndTime={generalCalendarAvailability.end}
          disableEventDragging={true}
          renderEventMenu={(obj, id, onUpdateEvent, onCloseEvent) => {
            if (!id) {
              // if no ID is passed, it means they've stopped focusing on an event
              // so we want to wipe and changes we made to our events array
              if (editingEvent.current) {
                copyAppointmentsToEvents()
                editingEvent.current = null
              }
              if (editingClosure.current) {
                const editingClosureBuildingId = closuresLookup[editingClosure.current].buildingId
                handleUpdatedResourceSettingsForBuilding(
                  buildingResourceSettingsCache.current[editingClosureBuildingId],
                  editingClosureBuildingId,
                )
                editingClosure.current = null
              }
              return null
            } else if (DUMMY_EVENT_ID === id) {
              return (
                <div className={classes.formContainer}>
                  <TimeBlockOffForm
                    createOrUpdateCalendarTimeBlockOff={(form, isDeleted = false) => {
                      createOrUpdateCalendarTimeBlockOff(form, isDeleted).then(onCloseEvent)
                    }}
                    onChange={d => {
                      onUpdateEvent({...obj, ...getColumnStartAndEndTimes(d)})
                    }}
                    closureData={obj}
                    onCancel={onCloseEvent}
                  />
                </div>
              )
            } else if (closuresLookup[id]) {
              return (
                <div className={classes.formContainer}>
                  <TimeBlockOffForm
                    createOrUpdateCalendarTimeBlockOff={(form, isDeleted = false) => {
                      createOrUpdateCalendarTimeBlockOff(form, isDeleted).then(onCloseEvent)
                    }}
                    onChange={handleUpdateClosure}
                    closureData={closuresLookup[id]}
                    onCancel={onCloseEvent}
                  />
                </div>
              )
            } else {
              return (
                <div className={classes.formContainer}>
                  <VendorAppointmentForm
                    appointmentId={id}
                    onUpdateForm={handleUpdateAppointment}
                    onCancel={onCloseEvent}
                    onSaveComplete={() => {
                      editingEvent.current = null
                      onCloseEvent()
                    }}
                  />
                </div>
              )
            }
          }}
        />
      )}
    </div>
  )
}

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  loading: {
    textAlign: 'center',
  },

  formContainer: {
    maxWidth: '20vw',
  },

  eventWrapper: {
    padding: Spacing.spaceSmall,
    textAlign: 'center',
    '& strong': {
      fontSize: '1.2em',
      fontWeight: 'bold',
    },
    '& p:last-child': {
      fontSize: '0.8em',
      fontStyle: 'italic',
    },
  },
}))

WeekCalendar.propTypes = {
  date: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
}

/**
 * @param {{startAt: Date|string, endAt: Date|string}} r
 * @return {{column: string, start: number, end: number}}
 */
function getColumnStartAndEndTimes(r) {
  const startOfDay = moment(r.startAt).startOf('day')
  return {
    column: moment(r.startAt).format('ddd'),
    start: moment(r.startAt).diff(startOfDay, 'minutes'),
    end: moment(r.endAt).diff(startOfDay, 'minutes'),
  }
}

export default WeekCalendar

/**
 * @type {Object<string, string>}
 */
const fadeColorCache = {}

/**
 * @param {string} hex
 * @param {number} opacity
 * @returns {string}
 */
function fadeColor(hex, opacity) {
  const cacheKey = hex + opacity

  if (!fadeColorCache[cacheKey]) {
    if (opacity < 0) opacity = 0
    if (opacity > 1) opacity = 1

    hex = hex.replace('#', '')

    let r = parseInt(hex.substring(0, 2), 16)
    let g = parseInt(hex.substring(2, 4), 16)
    let b = parseInt(hex.substring(4, 6), 16)

    fadeColorCache[cacheKey] = `rgba(${r}, ${g}, ${b}, ${opacity})`
  }

  return fadeColorCache[cacheKey]
}
