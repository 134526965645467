import React, {useState} from 'react'
import {Layout, Spacing, Colors} from '../../assets/styles'
import PageHeader from '../PageHeader'
import {makeStyles} from '@mui/styles'
import WeekCalendar from './WeekCalendar'
import {useSelector} from 'react-redux'
import DateInputWeek from '../DateInputWeek'
import moment from 'moment'
import SelectedBuildingsLegend from './SelectedBuildingsLegend'
import {VENDOR_SERVICE_CANCELLED, VENDOR_SERVICE_DECLINED, VENDOR_SERVICE_VENDOR_NO_SHOW} from '../../constants/VendorBookingConstants'

function CalendarPage() {
  const classes = useStyles()
  const buildingIds = useSelector(state => state.User.buildingIds)
  const appointmentsLookup = useSelector(state => state.Appointments.appointmentsLookup)

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [selectedBuildings, setSelectedBuildings] = useState(buildingIds)

  const startOfWeek = moment(selectedDate).startOf('week').toDate()
  const endOfWeek = moment(selectedDate).endOf('week').toDate()
  const appointments = Object.values(appointmentsLookup).filter(
    a => selectedBuildings.includes(a.buildingId) && a.startAt >= startOfWeek && a.startAt <= endOfWeek && shouldShow(a.status),
  )

  return (
    <div style={Layout.PageContainer}>
      <PageHeader title={'Calendar'} />
      <div className={classes.mainContainer}>
        <div className={classes.controls}>
          <div className={classes.row}>
            <SelectedBuildingsLegend selected={selectedBuildings} onChange={setSelectedBuildings} />
          </div>
          <DateInputWeek date={selectedDate} onChange={d => setSelectedDate(d)} />
        </div>
        <WeekCalendar date={selectedDate} appointments={appointments} selectedBuildings={selectedBuildings} />
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    backgroundColor: Colors.rxrContainerColor,
    flex: 1,
    width: '100%',
    overflowX: 'auto',
    paddingTop: Spacing.spaceMedium,
  },

  controls: {
    padding: `0 ${Spacing.spaceLarge}px ${Spacing.spaceLarge}px`,
  },

  row: {
    display: 'flex',
    marginBottom: Spacing.spaceMedium,
  },
}))

export default CalendarPage

function shouldShow(status) {
  return ![VENDOR_SERVICE_CANCELLED, VENDOR_SERVICE_DECLINED, VENDOR_SERVICE_VENDOR_NO_SHOW].includes(status)
}
