import React from 'react'
import SortableTable, {ColumnConfig, SORT_ASCENDING} from '../SortableTable'
import PropTypes from 'prop-types'
import moment from 'moment'
import HighlightText from '../HighlightText'
import {useSelector} from 'react-redux'
import {
  VENDOR_SERVICE_CONFIRMED,
  VENDOR_SERVICE_COMPLETED_PAID,
  VENDOR_SERVICE_COMPLETED,
  VENDOR_SERVICE_CANCELLED,
  VENDOR_SERVICE_VENDOR_NO_SHOW,
} from '../../constants/VendorBookingConstants'

const STATUS_TO_VENDOR_READABLE_STATUS = {
  [VENDOR_SERVICE_CONFIRMED]: 'Scheduled',
  [VENDOR_SERVICE_COMPLETED_PAID]: 'Completed',
  [VENDOR_SERVICE_COMPLETED]: 'Completed',
  [VENDOR_SERVICE_CANCELLED]: 'Cancelled',
  [VENDOR_SERVICE_VENDOR_NO_SHOW]: 'Vendor no Show',
}

const VendorBookingsTable = function (props) {
  const servicesLookup = useSelector(state => state.User.servicesLookup)
  const buildingsLookup = useSelector(state => state.User.buildingsLookup)

  const columns = [
    new ColumnConfig({
      title: 'Location',
      renderPrimitive: g => buildingsLookup[g.buildingId].displayName,
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),

    new ColumnConfig({
      title: 'Date',
      renderPrimitive: g => moment(g.startAt).format('MM/DD/YY'),
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: (a, b) => moment(a.startAt) - moment(b.startAt),
      sortingDirection: SORT_ASCENDING,
      isDefaultSort: true,
    }),

    new ColumnConfig({
      title: 'Time',
      renderPrimitive: g => `${moment(g.startAt).format('h:mma')} - ${moment(g.endAt).format('h:mma')}`,
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: (a, b) => moment(a.startAt) - moment(b.startAt),
    }),

    new ColumnConfig({
      title: 'Resident name',
      renderPrimitive: g => g.resident.displayName,
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),

    new ColumnConfig({
      title: 'Unit',
      renderPrimitive: g => g.resident.unitNumber,
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),

    new ColumnConfig({
      title: 'Service',
      renderPrimitive: g => `${servicesLookup[g.vendorServiceId].bedLabel} ${servicesLookup[g.vendorServiceId].label}`,
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),

    new ColumnConfig({
      title: 'Add ons',
      renderPrimitive: g => g.addOns.map(a => a.label).join(', ') || 'None',
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),

    new ColumnConfig({
      title: 'Status',
      renderPrimitive: g => STATUS_TO_VENDOR_READABLE_STATUS[g.status],
      render: (g, col) => <HighlightText search={props.filterTerm}>{col.renderPrimitive(g)}</HighlightText>,
      comparator: ColumnConfig.simplePrimitiveComparatorGenerator(),
    }),
  ]

  return <SortableTable data={props.vendorBookings} columns={columns} initialPage={props.page} onSetPage={props.setPage} />
}

VendorBookingsTable.propTypes = {
  vendorBookings: PropTypes.array.isRequired,
  filterTerm: PropTypes.string,
  page: PropTypes.number,
  setPage: PropTypes.func,
}

export default VendorBookingsTable
