import React, {useState, useEffect} from 'react'
import {Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material'
import {makeStyles} from '@mui/styles'
import {Typography as TypographyStyle} from '../assets/styles'
import {rxrTealColor, rxrLightGreyColor, rxrTeal15Color, rxrWhiteColor} from '../assets/styles/color'
import PropTypes from 'prop-types'
import {followDotNotation} from '../Utils/objectUtil'
import Pagination from '@mui/material/Pagination'
import DownloadData from './DownloadData'
import RXRIcon from './RXRIcon'
import {spaceExtraSmall} from '../assets/styles/spacing'
import CustomCheckbox from './CustomCheckbox'
import {useSelector} from 'react-redux'

const paginationHeight = '75px'

const useStyles = makeStyles(theme => ({
  mainContainer: props => ({
    minHeight: '100%',
    maxWidth: props.isMobile ? '100%' : undefined,
    position: 'relative',
    backgroundColor: rxrWhiteColor,
  }),
  tableContainer: props => ({
    width: '100%',
    overflowX: props.isMobile ? 'auto' : 'hidden',
  }),
  table: {
    '& .MuiTableCell-root:first-child': {
      paddingLeft: '30px', // we want a little extra padding on the first column
    },
  },
  tHead: {
    position: 'relative',
    backgroundColor: rxrLightGreyColor,
  },
  tbHeadCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  tbHeadCell: {
    ...TypographyStyle.BodyHeader,
    fontWeight: 'bold',
  },
  tbHeadCellSorting: {
    ...TypographyStyle.BodyHeader,
    fontWeight: 'bold',
    cursor: 'pointer',
    userSelect: 'none',
    color: rxrTealColor,
  },
  tbHeadCellSortable: {
    ...TypographyStyle.BodyHeader,
    fontWeight: 'bold',
    userSelect: 'none',
    cursor: 'pointer',
  },
  tbRow: {
    backgroundColor: rxrWhiteColor,
  },
  tbRowHighlighted: {
    backgroundColor: rxrTeal15Color,
  },
  tbCell: {
    ...TypographyStyle.BodyHeader,
  },
  tbCellCenter: {
    ...TypographyStyle.BodyHeader,
    textAlign: 'center',
  },
  paginationPlaceholder: {
    height: paginationHeight,
  },
  paginationContainer: {
    backgroundColor: rxrWhiteColor,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    textAlign: 'center',
    height: paginationHeight,
  },
  pagination: {
    margin: '0 auto',
    padding: 20,
    display: 'inline-block',
  },
  downloadIconContainer: props => ({
    position: 'absolute',
    top: 0,
    right: props.isMobile ? '0px' : '24px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }),
  downloadIcon: {
    lineHeight: 0,
  },
}))

export const SORT_ASCENDING = 'asc'
export const SORT_DESCENDING = 'desc'
const SORT_DEFAULT = SORT_ASCENDING

// We assume every record has an ID to extract the primary key. TODO: let this be overridden via props
const keyExtractor = r => r.id

const SortableTable = function (props) {
  const [sortingColumnTitle, setSortingColumnTitle] = useState()
  const [sortingDirection, setSortingDirection] = useState(SORT_DEFAULT)
  const isMobile = useSelector(state => state.App.isMobile)
  const [page, setPage] = useState(props.initialPage ? props.initialPage : 1)

  useEffect(() => {
    if (typeof props.initialPage === 'number') {
      setPage(props.initialPage)
    }
  }, [props.initialPage])

  const handlePageChange = (event, value) => {
    // if you change pages, we clear your selection
    if (typeof props.setMultiSelectSelectedArray === 'function') {
      props.setMultiSelectSelectedArray([])
    }
    setPage(value)
    if (props.onSetPage) {
      props.onSetPage(value)
    }
  }

  const toggleSorting = column => {
    // if this column isn't sortable, we exit
    if (!column.isSortable) return

    // if we're already sorting by the column
    if (sortingColumnTitle === column.title) {
      // we change the direction
      setSortingDirection(sortingDirection === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING)
    } else {
      // we set this column as the new sort column
      setSortingColumnTitle(column.title)

      // and we start off sorting ascending
      setSortingDirection(column.sortingDirection || SORT_DEFAULT)
    }
  }

  useEffect(() => {
    const defaultSortColumn = props.columns.find(c => c.isDefaultSort)
    if (defaultSortColumn) {
      toggleSorting(defaultSortColumn)
    }
  }, [])

  // assume we're not sorting
  let sortedData = props.data

  // if we're sorting by a column then we sort our data according to that column's comparator function
  if (sortingColumnTitle) {
    sortedData = props.data.sort((a, b) => {
      const column = props.columns.find(c => c.title === sortingColumnTitle)
      let result = column.comparator(a, b, column)

      if (sortingDirection === SORT_DESCENDING) {
        // JS comparators assume an ascending sort order, so if we want to sort descending, we just reverse the result
        result *= -1
      }
      return result
    })
  }

  // Adding Condition for Pagination
  const dataPerPage = 20
  const indexOfLastPageData = page * dataPerPage
  const indexOfFirstPageData = indexOfLastPageData - dataPerPage
  const currentSortedData = sortedData.slice(indexOfFirstPageData, indexOfLastPageData)

  const classes = useStyles({isMobile})
  const hasRowClick = typeof props.onClickRow === 'function'

  let visibleColumns = props.columns.filter(c => !c.isHidden)
  const selectedIds = Array.isArray(props.multiSelectSelectedArray) ? props.multiSelectSelectedArray.map(keyExtractor) : []

  if (props.hasMultiSelect) {
    const areAllSelected = currentSortedData.every(d => selectedIds.includes(keyExtractor(d)))
    visibleColumns = [
      new ColumnConfig({
        title: (
          <CustomCheckbox
            isChecked={props.multiSelectSelectedArray.length > 0 && areAllSelected}
            onChange={checked => {
              props.setMultiSelectSelectedArray(checked ? currentSortedData : [])
            }}
            isIndeterminate={props.multiSelectSelectedArray.length > 0 && !areAllSelected}
            key="selectAll"
            hasHoverEffect={true}
          />
        ),
        render: g => (
          <CustomCheckbox
            isChecked={selectedIds.includes(g.id)}
            onChange={checked => {
              props.setMultiSelectSelectedArray(
                checked
                  ? [...props.multiSelectSelectedArray, g]
                  : props.multiSelectSelectedArray.filter(r => keyExtractor(r) !== keyExtractor(g)),
              )
            }}
            hasHoverEffect={true}
          />
        ),
      }),
      ...visibleColumns,
    ]
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead className={classes.tHead}>
            <TableRow>
              {visibleColumns.map((c, index) => {
                let endPaddingStyle = props.downloadFileName && index === visibleColumns.length - 1 ? {paddingRight: '60px'} : null
                const isSorting = c.title === sortingColumnTitle
                const key = typeof c.title === 'object' ? c.title.key : c.title

                return (
                  <TableCell
                    key={key}
                    className={isSorting ? classes.tbHeadCellSorting : classes.tbHeadCellSortable}
                    onClick={() => toggleSorting(c)}
                    style={endPaddingStyle}
                  >
                    <div className={classes.tbHeadCellContainer}>
                      {c.title}
                      {c.isSortable && (
                        <RXRIcon
                          icon={
                            !isSorting
                              ? RXRIcon.SORT_NONE
                              : sortingDirection === SORT_DESCENDING
                              ? RXRIcon.SORT_DESCENDING
                              : RXRIcon.SORT_ASCENDING
                          }
                          style={{marginLeft: spaceExtraSmall}}
                        />
                      )}
                    </div>
                  </TableCell>
                )
              })}
              {props.downloadFileName && (
                <TableCell className={classes.downloadIconContainer}>
                  <DownloadData
                    className={classes.downloadIcon}
                    fileName={props.downloadFileName}
                    columns={props.columns}
                    data={props.data}
                  />
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentSortedData.map((d, i) => {
              const shouldHighlightRow = typeof props.rowHighlightCondition === 'function' && props.rowHighlightCondition(d)
              return (
                <TableRow
                  key={i}
                  className={shouldHighlightRow ? classes.tbRowHighlighted : classes.tbRow}
                  style={hasRowClick ? {cursor: 'pointer'} : null}
                  onClick={() => (hasRowClick ? props.onClickRow(d) : null)}
                >
                  {visibleColumns.map((c, j) => {
                    const key = typeof c.title === 'object' ? c.title.key : c.title
                    return (
                      <TableCell
                        key={`${key}-${j}`}
                        className={c.align === ColumnConfig.ALIGN_CENTER ? classes.tbCellCenter : classes.tbCell}
                      >
                        {c.render(d, i, currentSortedData)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
      {sortedData && sortedData.length > 0 && (
        <React.Fragment>
          <div className={classes.paginationPlaceholder} />
          <div className={classes.paginationContainer}>
            <Pagination
              className={classes.pagination}
              count={Math.ceil(sortedData.length / dataPerPage)}
              page={page}
              onChange={handlePageChange}
            />
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export class ColumnConfig {
  /**
   * @param {{
   *  title: string|*,
   *  render?: ColumnConfig~render,
   *  renderPrimitive?: ColumnConfig~render,
   *  comparator?: function,
   *  isDefaultSort?: boolean,
   *  sortingDirection?: string,
   *  align?: string,
   *  downloadOnly?: boolean,
   * }} conf
   */
  constructor(conf) {
    if (!conf.render && !conf.renderPrimitive) {
      throw new Error(`Need a render function for column "${conf.title}"`)
    }

    this.title = conf.title
    if (typeof conf.renderPrimitive === 'function') {
      this.renderPrimitive = (item, index, arr) => {
        try {
          return conf.renderPrimitive(item, this, index, arr)
        } catch (err) {
          console.warn(err)
          return ''
        }
      }
    }

    if (typeof conf.render === 'function') {
      this.render = (item, index, arr) => {
        try {
          return conf.render(item, this, index, arr)
        } catch (err) {
          console.warn(err)
          return ''
        }
      }
    } else {
      // you can specify just render, or just renderPrimitive, but render will always exist
      this.render = this.renderPrimitive
    }

    this.comparator = conf.comparator
    this.isSortable = typeof this.comparator === 'function'
    this.isDefaultSort = !!(conf.isDefaultSort && this.isSortable)
    this.sortingDirection = conf.sortingDirection
    this.align = conf.align || ColumnConfig.ALIGN_LEFT
    this.isHidden = !!conf.downloadOnly
  }
}
/**
 * @callback ColumnConfig~render
 * @param {*} item
 * @param {ColumnConfig} column
 * @param {number} index
 * @param {Array<*>} arr
 */

ColumnConfig.ALIGN_LEFT = 'left'
ColumnConfig.ALIGN_CENTER = 'center'

// by default, we mutate all strings to lower case
const DEFAULT_MUTATE = str => (typeof str === 'string' ? str.toLowerCase() : str)

/**
 * @param {string} property
 * @param {function?} tieBreaker
 * @param {function?} mutate
 * @returns {function(*, *)}
 */
ColumnConfig.simpleComparatorGenerator = (property, tieBreaker, mutate) => {
  // if no mutation function is passed, we use the default mutation function
  if (mutate === undefined) {
    mutate = DEFAULT_MUTATE
  }

  // this comparator simply compares the values by magnitude (after mutation)
  return (a, b) => {
    let aValue = typeof mutate === 'function' ? mutate(followDotNotation(a, property, true)) : followDotNotation(a, property, true)
    let bValue = typeof mutate === 'function' ? mutate(followDotNotation(b, property, true)) : followDotNotation(b, property, true)
    return aValue < bValue ? -1 : aValue > bValue ? 1 : typeof tieBreaker === 'function' ? tieBreaker(a, b) : 0
  }
}

/**
 * @returns {function(*, *, ColumnConfig): number}
 */
ColumnConfig.simplePrimitiveComparatorGenerator = () => {
  return (a, b, col) => {
    const aPrim = col.renderPrimitive(a)
    const bPrim = col.renderPrimitive(b)
    return aPrim < bPrim ? -1 : aPrim > bPrim ? 1 : 0
  }
}

/**
 * @param {function} getResidentFunction
 * @param {function?} tieBreaker
 * @param {string?} residentIdKey
 * @return {function(*, *)}
 */
ColumnConfig.residentNameComparatorGenerator = (getResidentFunction, tieBreaker, residentIdKey = 'residentId') => {
  return ColumnConfig.simpleComparatorGenerator(residentIdKey, tieBreaker, residentId => getResidentFunction(residentId).displayName)
}

/**
 * @param {function} getResidentFunction
 * @param {function?} tieBreaker
 * @param {string?} residentIdKey
 * @return {function(*, *)}
 */
ColumnConfig.residentUnitComparatorGenerator = (getResidentFunction, tieBreaker, residentIdKey = 'residentId') => {
  return (a, b) => {
    let residentA = getResidentFunction(a[residentIdKey])
    let residentB = getResidentFunction(b[residentIdKey])
    const unitA = residentA.occupancy.unit.number
    const unitB = residentB.occupancy.unit.number

    // We will use this function to slice off any letters from the beginning of the Unit number, as those
    // letters do not play well with the parseInt function we will utilize below
    const sliceOffBeginningLettersOfUnit = unit => {
      const firstNumberIndex = unit.search(/[0-9]/)
      return unit.slice(firstNumberIndex)
    }

    const reformattedUnitA = sliceOffBeginningLettersOfUnit(unitA)
    const reformattedUnitB = sliceOffBeginningLettersOfUnit(unitB)

    // we want to parseInt so that 1500A is treated as greater than 500A
    const aParsed = parseInt(reformattedUnitA)
    const bParsed = parseInt(reformattedUnitB)

    return aParsed === bParsed
      ? residentA.occupancy.unit.number < residentB.occupancy.unit.number
        ? -1
        : residentA.occupancy.unit.number > residentB.occupancy.unit.number
        ? 1
        : typeof tieBreaker === 'function'
        ? tieBreaker(a, b)
        : 0
      : aParsed - bParsed
  }
}

SortableTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onClickRow: PropTypes.func,
  rowHighlightCondition: PropTypes.func,
  downloadFileName: PropTypes.string,
  hasMultiSelect: PropTypes.bool,
  multiSelectSelectedArray: PropTypes.array, // an array of objects that indicate which records in the data array are selected
  setMultiSelectSelectedArray: PropTypes.func, // a callback function that receives an array of selected objects
  initialPage: PropTypes.number,
  onSetPage: PropTypes.func,
}

export default SortableTable
