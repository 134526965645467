export const rxrBlueColor = '#0C4067'
export const rxrSoftHighlightedBlue = '#2C5D83'
export const rxrTealColor = '#2E9CCA'
export const rxrTeal15Color = 'rgba(46, 156, 202, 0.15)'
export const rxrTeal25Color = 'rgba(46, 156, 202, 0.25)'

export const rxrBlackColor = '#000000'
export const rxrDarkGreyColor = '#5E5E5E'
export const rxrMediumGreyColor = '#919191'
export const rxrMediumLightGreyColor = '#b0b0b0'
export const rxrGreyColor = '#E0E0E0'
export const rxrLightGreyColor = '#F0F0F0'
export const nearWhiteColor = '#F8F8F8'
export const rxrWhiteColor = '#FFFFFF'

export const rxrRedColor = '#E02020'
export const rxrGreenColor = '#009A02'
export const rxrSuccessColor = '#6ED16F'
export const rxrSuccess25Color = 'rgba(110, 209, 111, 0.25)'
export const rxrYellowColor = '#F9BA30'
export const rxrOrangeColor = '#ff9901'
export const rxrStatusGreenColor = '#009A02'
export const rxrMonotoneLightGreyColor = '#D8D8D8'
export const rxrContainerColor = '#F5F5F5'
export const rxrWarmBrown = '#C09891'
export const rxrErrorColor = '#FE4E5E'
export const rxrMidBlue = '#95C6DB'
export const rxrSecondaryBlueGrayColor = '#96A7BE'
export const rxrBlueDarkColor = '#427FC7'
export const rxrBlackDarkerColor = '#0C2D54'
export const rxrTeal25to100 = '#CAE6F1'
export const rxrSidePanelBackgroundCoor = '#FAFAFC'
export const rxrSecondaryMidDarkBlue = '#859FB3'
export const rxrLightTeal = '#9ADBF6'
export const whiteOverlay50 = 'rgba(255,255,255,0.5)'
export const blackOverlay50 = 'rgba(0,0,0,0.5)'
export const blackOverlay80 = 'rgba(0,0,0,0.8)'

export const messageBubbleColor = rxrGreyColor
export const messageBubbleColorMine = '#66c6ff'
export const messageBubbleColorPeer = '#d2eeff'
export const magenta = '#DB76FF'
export const magentaLight = '#e9b6ff'

export const purple = '#7300E6'

// Primary color
export const rxrBlue = {
  color: rxrBlueColor,
}

// Monotone color

export const black = {
  color: rxrBlackColor,
}

export const darkGrey = {
  color: rxrDarkGreyColor,
}

// Status color

export const red = {
  color: rxrRedColor,
}

export const backgroundLightGrey = {
  backgroundColor: rxrLightGreyColor,
}

export const backgroundWhite = {
  backgroundColor: rxrWhiteColor,
}

export const shadowSoft = '0px 4px 20px 4px rgba(0, 0, 0, 0.05)'

export const shadowHard = '0px 4px 10px 0px rgba(0, 0, 0, 0.1)'
